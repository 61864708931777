<script>
  // # # # # # # # # # # # # #
  //
  //  gawedakulbokaite.com
  //
  // # # # # # # # # # # # # #

  // IMPORTS
  import { Router, Route } from "svelte-routing"
  import { loadData } from "./sanity.js"

  // POLYFILL
  import smoothscroll from "smoothscroll-polyfill"
  smoothscroll.polyfill()

  // STORES
  import {
    defaultBackgroundColor,
    defaultTextColor,
    defaultHoverColor,
    backgroundColor,
    textColor,
    hoverColor,
    aboutContent,
    aboutDownloads,
    aboutContact,
  } from "./stores.js"

  $: {
    document.getElementsByTagName("BODY")[0].style.background = $backgroundColor
    document.documentElement.style.background = $backgroundColor
  }

  // COMPONENTS
  import Footer from "./Components/Footer.svelte"
  import MenuBar from "./Components/MenuBar.svelte"

  // ROUTES
  import Landing from "./Routes/Landing.svelte"
  import Single from "./Routes/Single.svelte"
  import About from "./Routes/About.svelte"
  import Error404 from "./Routes/Error404.svelte"

  const landingQuery = "*[_id == '7c7e3fd6-629b-47d9-93b0-b496f778f032'][0]"

  let aboutPost = loadData(landingQuery)

  aboutPost.then(a => {
    // ABOUT CONTENT
    aboutContent.set(a.content)
    // ABOUT DOWNLOADS
    aboutDownloads.set(a.downloads)
    // ABOUT CONTACT
    aboutContact.set(a.contact)
    // BACKGROUND COLOR
    if (a.backgroundColor) {
      defaultBackgroundColor.set(a.backgroundColor.hex)
    }
    // TEXT COLOR
    if (a.textColor) {
      defaultTextColor.set(a.textColor.hex)
    }
    // HOVER COLOR
    if (a.hoverColor) {
      defaultHoverColor.set(a.hoverColor.hex)
    }
  })
</script>

<MenuBar />

<Router>
  <Route path="/" component={Landing} title="landing" />
  <Route path="/about" component={About} title="about" />
  <Route path="/filter/:term" component={Landing} title="landing" />
  <Route path="/:slug" component={Single} title="single" />
  <Route component={Error404} title="404" />
</Router>

<style lang="scss" global>
  @import "./variables.scss";
  @import "./flickity.scss";

  html,
  body {
    padding: 0;
    margin: 0;
    background: white;
  }

  p {
    margin: 0;
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(220, 220, 220, 0.2);

    &::selection {
      background: rgba(220, 220, 220, 0.3); /* WebKit/Blink Browsers */
    }

    &::-moz-selection {
      background: rgba(220, 220, 220, 0.3); /* Gecko Browsers */
    }
  }

  .meta {
    p {
      display: inline;
    }
  }

  .about {
    a {
      font-weight: 600;
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  a {
    color: inherit;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .content-item {
    opacity: 0;
    transition: opacity 0.5s $transition;
    &.loaded {
      opacity: 1;
    }

    video {
      width: 720px;
      max-width: 100%;
    }
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 720px;
    width: 100%;
    margin-bottom: 1em;
    background: red;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .soundcloud-container {
    width: 100%;
    max-width: 600px;
    overflow: hidden;
    margin-bottom: 1em;

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
</style>
