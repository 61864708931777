import { writable } from 'svelte/store'

// WRITABLE
export const menuBarText = writable(false)
export const location = writable('index')
export const filterTerm = writable(false)
export const searchTerm = writable(false)
export const savedPosts = writable([])
export const returnFromPost = writable(false)
// COLORS
export const backgroundColor = writable('')
export const textColor = writable('')
export const hoverColor = writable('')
export const defaultBackgroundColor = writable('')
export const defaultTextColor = writable('')
export const defaultHoverColor = writable('')
// ABOUT
export const aboutContent = writable(false)
export const aboutDownloads = writable([])
export const aboutContact = writable('')
