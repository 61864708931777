<script>
  // # # # # # # # # # # # # #
  //
  //  TYPER
  //
  // # # # # # # # # # # # # #

  // *** IMPORT
  import { onMount, onDestroy } from "svelte";

  // *** PROPS
  export let text = "";

  // *** VARIABLES
  let textOut = "";
  let i = 1;
  let timer = {};

  // *** ON MOUNT
  onMount(async () => {
    timer = setInterval(() => {
      if (i == text.length) {
        clearInterval(timer);
      } else {
        textOut = text.substring(0, i);
        i++;
      }
    }, 30);
  });

  // *** ON DESTROY
  onDestroy(() => {
    clearInterval(timer);
  });
</script>

<span>{textOut}</span>
