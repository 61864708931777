<script>
  // # # # # # # # # # # # # #
  //
  //  ERROR 404
  //
  // # # # # # # # # # # # # #

  // STORES
  import {
    backgroundColor,
    textColor,
    hoverColor,
    aboutContent,
    aboutDownloads,
    aboutContact,
    defaultBackgroundColor,
    defaultTextColor,
    defaultHoverColor,
  } from "../stores.js"

  $: {
    // BACKGROUND COLOR
    backgroundColor.set($defaultBackgroundColor)
    // TEXT COLOR
    textColor.set($defaultTextColor)
    // HOVER COLOR
    hoverColor.set($defaultHoverColor)
  }
</script>

<svelte:head>
  <title>404</title>
</svelte:head>

<div class="placeholder">
  <strong>404:</strong>
  Page not found
</div>

<style lang="scss">
  @import "../variables.scss";

  .placeholder {
    margin-top: $menubar-height;
    padding-top: 0.4em;
    font-size: $large;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1em;
    font-family: $sans-stack;
    margin-left: 10px;
    min-height: 80vh;

    @include screen-size("small") {
      font-size: $mobile_xlarge;
    }
  }
</style>
