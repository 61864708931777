<script>
  // # # # # # # # # # # # # #
  //
  //  METADATA
  //
  // # # # # # # # # # # # # #

  // IMPORT
  import get from "lodash/get";
  import has from "lodash/has";
  import isArray from "lodash/isArray";
  import isEmpty from "lodash/isEmpty";
  import truncate from "lodash/truncate";
  import { format, getYear } from "date-fns";
  import { toPlainText, urlFor } from "../sanity.js";

  // *** STORES
  import { aboutContent, filterTerm } from "../stores.js";

  // *** PROPS
  export let post = false;

  // *** VARIABLES
  let title = "";
  let description = "";
  let image = "";
  let url = "";

  // FUNCTIONS
  const formattedDate = (start, end) => {
    if (!start) {
      return false;
    }

    const startDate = Date.parse(start);

    if (!end) {
      return format(startDate, "dd.MM.yyyy");
    }

    const endDate = Date.parse(end);

    if (format(startDate, "dd.MM.yyyy") == format(endDate, "dd.MM.yyyy")) {
      return format(startDate, "dd.MM.yyyy");
    }

    const startFormat =
      getYear(startDate) == getYear(endDate) ? "dd.MM" : "dd.MM.yyyy";
    const endFormat = "dd.MM.yyyy";

    return format(startDate, startFormat) + " – " + format(endDate, endFormat);
  };

  $: {
    if (post) {
      title = post.title + " / DOROTA GAWĘDA AND EGLĖ KULBOKAITĖ";
      url = "https://gawedakulbokaite.com/" + post.slug.current;
      if (isArray(post.description)) {
        description =
          post.location +
          ". " +
          formattedDate(post.startDate, post.endDate) +
          ". " +
          truncate(toPlainText(post.description), {
            length: 160,
            separator: /.? +/
          });
      } else {
        description =
          post.location +
          ". " +
          formattedDate(post.startDate, post.endDate) +
          ".";
      }
      image = has(post, "content")
        ? urlFor(post.content[0])
            .quality(80)
            .height(1200)
            .width(1200)
            .auto("format")
            .url()
        : "https://gawedakulbokaite.com/img/gawedakulbokaite.png";
    } else {
      title = $filterTerm
        ? $filterTerm.toUpperCase() + " / DOROTA GAWĘDA AND EGLĖ KULBOKAITĖ"
        : "DOROTA GAWĘDA AND EGLĖ KULBOKAITĖ";
      url = "https://gawedakulbokaite.com/";
      description = isArray($aboutContent)
        ? truncate(toPlainText($aboutContent), {
            length: 160,
            separator: /.? +/
          })
        : "";
      image = "https://gawedakulbokaite.com/img/gawedakulbokaite.png";
    }
  }
</script>

<svelte:head>
  <title>{title}</title>
  <meta property="og:title" content={title} />
  <meta property="og:url" content={url} />
  <meta property="twitter:title" content={title} />
  <meta property="description" content={description} />
  <meta property="og:description" content={description} />
  <meta property="twitter:description" content={description} />
  <meta property="image" content={image} />
  <meta property="og:image" content={image} />
  <meta property="twitter:image" content={image} />
</svelte:head>
