<script>
  // # # # # # # # # # # # # #
  //
  //  FOOTER
  //
  // # # # # # # # # # # # # #

  // // *** IMPORTS
  import { links } from "svelte-routing"

  // // COMPONENTS
  // import ListingItem from "../Components/ListingItem.svelte";

  // STORES
  import {
    location,
    filterTerm,
    backgroundColor,
    textColor,
    hoverColor,
    aboutContact,
  } from "../stores.js"

  const filterCategories = [
    {
      title: "SOLO",
      name: "solo-show",
    },
    {
      title: "GROUP",
      name: "group-show",
    },
    {
      title: "PERFORMANCE/EVENT",
      name: "performance",
    },
    // {
    //   title: "YGRG",
    //   name: "ygrg",
    // },
    // {
    //   title: "TALK/EVENT",
    //   name: "talk-event",
    // },
    {
      title: "RESIDENCY",
      name: "residency",
    },
    // {
    //   title: "ONLINE",
    //   name: "online",
    // },
    {
      title: "PRESS",
      name: "press",
    },
  ]
</script>

<footer style="background: {$backgroundColor}; color: {$textColor};" use:links>
  <!-- TITLE -->
  <div class="column">
    <div class="footer-item title">DOROTA GAWĘDA</div>
    <div class="footer-item title">EGLE KULBOKAITĖ</div>
  </div>
  <!-- ABOUT & CONTACT -->
  <div class="column">
    <a href="/about" class="footer-item">ABOUT</a>
    <a class="footer-item" href={"mailto:" + $aboutContact}>CONTACT</a>
  </div>
  <!-- CATEGORIES 1–4  -->
  <div class="column mobile-hide">
    {#each filterCategories.slice(0, 2) as cat, index}
      <a
        href={"/filter/" + cat.name}
        class="footer-item"
        class:selected={cat.name == $filterTerm}
      >
        {cat.title}
      </a>
    {/each}
  </div>
  <!-- CATEGORIES 5–8 -->
  <div class="column mobile-hide">
    {#each filterCategories.slice(2, 5) as cat, index}
      <a
        href={"/filter/" + cat.name}
        class="footer-item"
        class:selected={cat.name == $filterTerm}
      >
        {cat.title}
      </a>
    {/each}
  </div>
</footer>

<style lang="scss">
  @import "../variables.scss";

  footer {
    width: 100vw;
    overflow: hidden;
    user-select: none;

    @include screen-size("small") {
      padding-bottom: 3em;
    }

    .column {
      width: calc(25% - 10px);
      margin-left: 10px;
      float: left;
      padding-bottom: 3em;

      font-size: $small;
      font-weight: 500;
      line-height: 2em;
      text-transform: uppercase;
      font-family: $sans-stack;
      opacity: 1;
      letter-spacing: 1px;

      @include screen-size("small") {
        width: calc(100% - 20px);
        padding-bottom: 1em;
        margin-right: 10px;
      }

      &.mobile-hide {
        @include screen-size("small") {
          display: none;
        }
      }

      .footer-item {
        display: block;
        font-weight: normal;

        &.selected {
          font-weight: bold;
        }

        &.title {
          font-weight: bold;
        }
      }
    }
  }
</style>
